import "./../css/preloader.css"
function Preloader(){
    return(
        <div class="gooey">
  <span class="dot"></span>
  <div class="dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
    )
}

export default Preloader;